import { each, size } from "lodash"
import yup from "../../lib/yup"
import { contactNumber } from "./shared"

const references = yup
  .array()
  .min(1, "Please provide at least one reference")
  .test(
    "is-file-instance",
    ({ label }: any) => `${label} is not an instance of an uploaded file`,
    (value, context) => {
      each(value, (file: any, idx) => {
        if (!(file instanceof File)) {
          context.createError({
            path: `${context.path}[${idx.toString()}]`,
            message: "Value is not an instance of a File Upload",
            params: {
              file,
            },
          })
        }
      })

      return true
    }
  )
  .required("This is a required field")

export const schema = yup
  .object()
  .shape(
    {
      galleryName: yup
        .string()
        .trim()
        .required("Please enter the gallery name"),
      email: yup
        .string()
        .trim()
        .email("Please enter a valid email address")
        .required("Please enter your email address"),
      username: yup
        .string()
        .trim()
        .required("Please enter the gallery username"),
      // password: yup
      //   .string()
      //   .trim()
      //   .min(10, "Password must be 10 characters or longer")
      //   .matches(
      //     /^(?=.*[a-z])/,
      //     "Must Contain at least one lowercase character"
      //   )
      //   .matches(
      //     /^(?=.*[A-Z])/,
      //     "Must Contain at least one uppercase character"
      //   )
      //   .matches(
      //     /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
      //     "Must Contain at least one symbol"
      //   )
      //   .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain at least one number")
      //   .required(),
      // confirmPassword: yup
      //   .string()
      //   .trim()
      //   .oneOf(
      //     [yup.ref("password"), null],
      //     "Password and Confirmation must match"
      //   ),
      galleryRepresentativeFirstName: yup
        .string()
        .trim()
        .required("Please enter the name of the gallery representative"),
      galleryRepresentativeMiddleName: yup.string().trim(),
      galleryRepresentativeLastName: yup
        .string()
        .trim()
        .required("Please enter the last name of the gallery representative"),
      location: yup
        .string()
        .trim()
        .required("Please enter your location address or city"),
      hasGalleryWorkedWithNfts: yup
        .string()
        .trim()
        .required("Please describe your previous experience"),
      galleryDescription: yup
        .string()
        .trim()
        .required("Please enter a description"),
      website: yup
        .string()
        .transform(value => {
          if (!value) return undefined
          let url: string = value

          try {
            new URL(url)
          } catch (e) {
            url = `http://${url}`
          }

          return url
        })
        .url("Please enter a valid url")
        .required("Please enter the gallery website url"),
      facebookUrl: yup
        .string()
        .trim()
        .transform(value => {
          if (!value) return undefined
          let url: string = value

          try {
            new URL(url)
          } catch (e) {
            url = `http://${url}`
          }

          return url
        })
        .url("Please enter a valid facebook user url")
        .matches(/facebook.com/i, "Please enter a valid facebook user url"),
      instagramUrl: yup
        .string()
        .trim()
        .transform(value => {
          if (!value) return undefined
          let url: string = value

          try {
            new URL(url)
          } catch (e) {
            url = `http://${url}`
          }

          return url
        })
        .url("Please enter a valid instagram user url")
        .matches(/instagram.com/i, "Please enter a valid instagram user url"),
      twitterUrl: yup
        .string()
        .trim()
        .transform(value => {
          if (!value) return undefined
          let url: string = value

          try {
            new URL(url)
          } catch (e) {
            url = `http://${url}`
          }

          return url
        })
        .url("Please enter a valid twitter user url")
        .matches(/twitter.com/i, "Please enter a valid twitter user url"),
      references: yup.array().when("referenceLinks", {
        is(val: string[]) {
          return size(val) === 0
        },
        then: references,
      }),
      referenceLinks: yup
        .array()
        .of(yup.string().trim())
        .when("references", {
          is(val: File[]) {
            return size(val) === 0
          },
          then: yup
            .array()
            .of(yup.string().trim())
            .min(1, "Please provide at least one reference link"),
        }),
      walletAddress: yup.string().trim().required(),
    },
    // @ts-ignore
    ["references", "referenceLinks"]
  )
  .required()
