import React, { useEffect, useMemo, useState } from "react"

import { navigate } from "gatsby"
import { SubmitHandler, useForm } from "react-hook-form"
import FormLayout from "../../../components/FormLayout/FormLayout"
import InputUpload from "../../../components/InputUpload/InputUpload"

import { GallerySignUpInputs } from "../../../../types/pages/gallery-sign-up"
import * as styles from "./GallerySignUp.module.scss"
import { CustomTextAreaInput } from "../../../components/CustomTextAreaInput/CustomTextAreaInput"
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput"
import { ConnectWalletMessage } from "../../../components/ConnectWalletMessage/ConnectWalletMessage"
import { useWallet } from "use-wallet"
import Seo from "../../../components/Seo/seo"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "../../../validation/signups/gallery"

import { fromJSON } from "../../../lib/form-data"
import { SignUpButton } from "../../../components/SignUpButton/SignUpButton"
import { UserNameInput } from "../../../components/UserNameInput/UserNameInput"
import { ProgressBar } from "../../../components/ProgressBar/ProgressBar"
import {
  ErrorType,
  ServerErrorMessage,
  SignUpType,
} from "../../../components/ServerErrorMessage/ServerErrorMessage"
import { PasswordRequirements } from "../../../components/PasswordRequirements/PasswordRequirements"
import InputUploadImage from "../../../components/InputUploadImage/InputUploadImage"

const GallerySignUp = () => {
  const wallet = useWallet()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<GallerySignUpInputs>({
    resolver: yupResolver(schema),
    mode: "all",
  })

  const [, setWalletError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showServerError, setShowErrorMessage] = useState(false)
  const [serverErrorType, setServerErrorType] = useState<ErrorType>(
    ErrorType.Custom
  )
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 10)
  }, [])

  useMemo(() => {
    if (wallet.account) {
      setValue("walletAddress", wallet.account)
    }
  }, [wallet.account])

  const onSubmit: SubmitHandler<GallerySignUpInputs> = async data => {
    if (wallet.status !== "connected") {
      setWalletError(true)
      return
    }
    try {
      setIsLoading(true)
      setShowErrorMessage(false)
      data.facebookUrl = data.facebookUrl ?? ""
      data.twitterUrl = data.twitterUrl ?? ""
      data.instagramUrl = data.instagramUrl ?? ""
      data.profilePicture = data.profilePicture.shift()
      data.coverImage = data.coverImage.shift()
      const formData = fromJSON(data)

      const response = await fetch(
        new URL("/gallery", process.env.GATSBY_API_URL).toString(),
        {
          method: "POST",
          body: formData,
        }
      )

      if (response.ok) {
        navigate("/sign-up/confirmation")
      } else {
        const res = await response.json()
        if (res.statusCode >= 500) {
          setServerErrorType(ErrorType.Unknown)
        } else {
          setServerErrorType(ErrorType.Custom)
        }
        setShowErrorMessage(true)
      }
    } catch (ex) {
      setShowErrorMessage(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Seo title="Gallery Sign up" />
      {isLoading && <ProgressBar />}
      <div className={styles.galleryFormContainer}>
        <FormLayout
          title="Gallery Sign Up"
          onSubmit={handleSubmit(onSubmit)}
          icon="galleryBlueSquares"
          className={styles.galleryFormLayout}
          backgroundClassName={styles.galleryBackground}
        >
          <div className={styles.gallerySignUpForm}>
            <CustomTextInput
              errorMessage={errors.galleryName?.message}
              placeholder="Gallery Name"
              register={register("galleryName")}
            />
            <CustomTextInput
              errorMessage={errors.email?.message}
              placeholder="Email"
              register={register("email")}
            />
            <CustomTextInput
              errorMessage={errors.galleryRepresentativeFirstName?.message}
              placeholder="Gallery Representative First Name"
              register={register("galleryRepresentativeFirstName")}
            />
            <CustomTextInput
              errorMessage={errors.galleryRepresentativeMiddleName?.message}
              placeholder="Gallery Representative Middle Name"
              isOptional={true}
              register={register("galleryRepresentativeMiddleName")}
            />
            <CustomTextInput
              errorMessage={errors.galleryRepresentativeLastName?.message}
              placeholder="Gallery Representative Last Name"
              register={register("galleryRepresentativeLastName")}
            />
            <CustomTextInput
              errorMessage={errors.location?.message}
              placeholder={"Location"}
              register={register("location")}
            />
            <CustomTextInput
              errorMessage={errors.hasGalleryWorkedWithNfts?.message}
              placeholder={"Has your gallery work with NFTs before?"}
              register={register("hasGalleryWorkedWithNfts")}
            />
            <div className={styles.galleryWalletContainer}>
              <ConnectWalletMessage />
            </div>
          </div>

          <div className={styles.gallerySignUpForm}>
            <UserNameInput
              errorMessage={errors.username?.message}
              placeholder="Username"
              register={register("username")}
            />
            {/* <div className={styles.galleryPasswordContainer}>
              <CustomTextInput
                errorMessage={errors.password?.message}
                placeholder="Password"
                type="password"
                register={register("password")}
              />
              <PasswordRequirements
                classname={styles.galleryPasswordRequirements}
              />
              <CustomTextInput
                errorMessage={errors.confirmPassword?.message}
                placeholder="Re-enter Password"
                type="password"
                register={register("confirmPassword")}
              />
            </div> */}
            <CustomTextInput
              errorMessage={errors.website?.message}
              placeholder={"Website"}
              register={register("website")}
            />
            <CustomTextInput
              errorMessage={errors.facebookUrl?.message}
              placeholder={"Facebook URL"}
              isOptional={true}
              register={register("facebookUrl")}
            />
            <CustomTextInput
              errorMessage={errors.instagramUrl?.message}
              placeholder={"Instagram URL"}
              isOptional={true}
              register={register("instagramUrl")}
            />
            <CustomTextInput
              errorMessage={errors.twitterUrl?.message}
              placeholder={"Twitter URL"}
              isOptional={true}
              register={register("twitterUrl")}
            />
            <InputUpload
              register={register("references")}
              setValue={setValue}
              errors={errors}
            />
            <InputUploadImage
              register={register("profilePicture")}
              setValue={setValue}
              errors={errors}
              name={"profilePicture"}
              errorMessage={errors.profilePicture?.message}
              maxFiles={1}
              subtitle={"Profile Picture (1 example, 15mb limit)"}
              title={"Drag and drop your profile picture here."}
            />
            <InputUploadImage
              register={register("coverImage")}
              setValue={setValue}
              errors={errors}
              name={"coverImage"}
              errorMessage={errors.coverImage?.message}
              maxFiles={1}
              subtitle={"Cover Image (1 example, 15mb limit)"}
              title={"Drag and drop your Cover Image here."}
            />
            <CustomTextAreaInput
              errorMessage={errors.galleryDescription?.message}
              placeholder={"Gallery Description (1000 characters)"}
              register={register("galleryDescription")}
            />
          </div>

          <div className={styles.gallerySignUpButtonsContainer}>
            <SignUpButton
              title={"→ Send Application"}
              spanText={"All fields are required unless otherwise indicated"}
              isDisabled={
                wallet.status !== "connected" ||
                Object.keys(errors).length > 0 ||
                isLoading
              }
            />
            {showServerError && (
              <ServerErrorMessage
                errorType={serverErrorType}
                signuptype={SignUpType.Gallery}
                className={styles.gallerySignUpServerError}
                errorTitle={"Submission denied: Account already created"}
                errorDetail={
                  "If you wish to sign up for an additional account, please use a different email."
                }
              />
            )}
          </div>
        </FormLayout>
      </div>
    </>
  )
}

export default GallerySignUp
