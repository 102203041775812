// extracted by mini-css-extract-plugin
export var disableButton = "GallerySignUp-module--disableButton--a6927";
export var galleryBackground = "GallerySignUp-module--galleryBackground--86997";
export var galleryConnectWalletButtons = "GallerySignUp-module--galleryConnectWalletButtons--f8e04";
export var galleryFormContainer = "GallerySignUp-module--galleryFormContainer--cd92e";
export var galleryFormLayout = "GallerySignUp-module--galleryFormLayout--6e0b8";
export var galleryPasswordContainer = "GallerySignUp-module--galleryPasswordContainer--02546";
export var galleryPasswordRequirements = "GallerySignUp-module--galleryPasswordRequirements--ba23c";
export var gallerySignUpButtons = "GallerySignUp-module--gallerySignUpButtons--98f66";
export var gallerySignUpButtonsContainer = "GallerySignUp-module--gallerySignUpButtonsContainer--e09bf";
export var gallerySignUpError = "GallerySignUp-module--gallerySignUpError--1baad";
export var gallerySignUpForm = "GallerySignUp-module--gallerySignUpForm--3af4f";
export var gallerySignUpServerError = "GallerySignUp-module--gallerySignUpServerError--1972a";